import { Chip, Avatar } from '@mui/material';
import ExportLogoSvg from '../assets/brands/export.svg';
import HeinekenLogoSvg from '../assets/brands/heineken.svg';
import TigerLogoSvg from '../assets/brands/tiger.svg';
import { Brand } from '../type/brand';
import { useEffect, useState } from 'react';

interface BrandChipProps {
	type: Brand;
	backgroundColor?: string;
}

interface style {
	backgroundColor: string;
	borderColor: string;
	logo: any;
	label: string;
	color: string;
}

const BrandChip = ({ type, backgroundColor }: BrandChipProps) => {
	const [style, setStyle] = useState<style>({
		backgroundColor: '',
		borderColor: '',
		logo: '',
		label: '',
		color: '',
	});

	const getType = () => {
		switch (type) {
			case Brand.EXPORT:
				setStyle({
					backgroundColor: '#FED00014',
					borderColor: '#FED0003D',
					color: '#000000DE',
					logo: ExportLogoSvg,
					label: 'Export',
				});
				break;
			case Brand.HEINEKEN:
				setStyle({
					backgroundColor: '#01712514',
					borderColor: '#0171253D',
					logo: HeinekenLogoSvg,
					label: 'Heineken',
					color: '#017125',
				});
				break;
			case Brand.TIGER:
				setStyle({
					backgroundColor: '#22356F14',
					borderColor: '#22356F14',
					logo: TigerLogoSvg,
					label: 'Tiger',
					color: '#22356F',
				});
				break;
		}

		if (backgroundColor) {
			setStyle((prevState) => ({
				...prevState,
				backgroundColor: backgroundColor,
			}));
		}
	};

	useEffect(() => {
		getType();
	}, [type]);

	return (
		<Chip
			avatar={<Avatar src={style.logo} />}
			label={style.label}
			sx={{
				backgroundColor: style.backgroundColor,
				border: `1px ${style.borderColor} solid`,
				fontFamily: 'Gotham-Medium',
				fontSize: '13px',
				color: style.color,
			}}
		/>
	);
};

export default BrandChip;
