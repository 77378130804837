import { Box, Radio } from '@mui/material';
import styled from 'styled-components';
import { VIEWPORT } from '../styles/viewport';

interface BrandOptionProps {
	label: string;
	image: any;
	logo: any;
	rotation: number;
	backgroundColor: string;
	imageHeight: number;
	imageWidth: number;
	isChecked: boolean;
	clicked?: () => void;
}

const TopContainer = styled.div<{
	isChecked: boolean;
	backgroundColor: string;
}>`
	transition: inherit;
	position: relative;
	height: 80px;
	border-radius: 16px;
	padding: 12px;
	background-color: ${({ isChecked, backgroundColor }) =>
		isChecked ? backgroundColor : '#CDCDCD'};
`;

const BottomContainer = styled(Box)`
	transition: inherit;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: auto;
	width: 100%;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
	}
`;

const TitleContainer = styled(Box)`
	transition: inherit;
	justify-content: center;
	text-align: center;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		text-align: left;
	}
`;

const BrandTitle = styled.div<{ isChecked: boolean }>`
	transition: inherit;
	font-family: 'Gotham-Bold';
	font-size: 18px;
	line-height: 20px;
	color: ${({ isChecked }) => (isChecked ? 'black' : '#757575')};

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		font-size: 20px;
		line-height: 24px;
	}
`;

const BrandSubTitle = styled.div`
	font-family: 'Gotham-Light';
	font-size: 11px;
	line-height: 12px;
	letter-spacing: 0.15px;
	color: #757575;
`;

const BrandGlassImage = styled.img<{
	isChecked: boolean;
	rotation: number;
	height: number;
	width: number;
}>`
	position: absolute;
	transition: inherit;
	width: ${({ isChecked, width }) =>
		isChecked ? `${width * 1.1}px` : `${width}px`};
	height: ${({ isChecked, height }) =>
		isChecked ? `${height * 1.1}px` : height};
	bottom: 5%;
	left: 20px;
	rotate: ${({ isChecked, rotation }) =>
		isChecked ? `${rotation}deg` : '0deg'};
	filter: grayscale(${({ isChecked }) => (isChecked ? '0%' : '100%')});

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
		width: ${({ isChecked, width }) =>
			isChecked ? `${width * 1.5}px` : `${width * 1.2}px`};
		height: ${({ isChecked, height }) =>
			isChecked ? `${height * 1.5}px` : `${height * 1.2}px`};
	}
`;

const BrandLogo = styled.img<{ isChecked: boolean }>`
	position: absolute;
	transition: inherit;
	width: 32px;
	height: 32px;
	z-index: 2;
	bottom: 5px;
	left: 5px;
	filter: grayscale(${({ isChecked }) => (isChecked ? '0%' : '100%')});

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		position: inherit;
		width: 48px;
		height: 48px;
	}
`;

const HiddenOnMobile = styled.div`
	display: none;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		display: inherit;
	}
`;

const HiddenOnDesktop = styled.div`
	display: inherit;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		display: none;
	}
`;

const StyledContainer = styled(Box)<{ isChecked?: boolean }>`
	display: flex;
	flex-direction: column;
	max-width: 124px;
	width: 100%;
	height: 182px;
	padding: 8px;
	background-color: #ffffff;
	border: 1px solid #0000003d;
	border-radius: 24px;
	transition: all 300ms ease-in-out;
	gap: 8px;
	cursor: pointer;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		max-width: 254px;
		width: 254px;
		height: 100%;

		&:hover {
			& ${BrandGlassImage} {
				transform: ${(isChecked) => isChecked && 'scale(1.2)'};
				filter: grayscale(${({ isChecked }) => (!isChecked ? '0%' : '0%')});
			}

			& ${BrandLogo} {
				filter: grayscale(${({ isChecked }) => (!isChecked ? '0%' : '0%')});
			}
		}

		&:active {
			& ${TopContainer} {
				transition: all 100ms;
				opacity: 80%;
			}
		}
	}
`;

const BrandOption = ({
	label,
	image,
	logo,
	rotation,
	backgroundColor,
	imageHeight,
	imageWidth,
	isChecked,
	clicked,
}: BrandOptionProps) => {
	return (
		<StyledContainer onClick={clicked} isChecked={isChecked}>
			{/** Image Content */}
			<TopContainer isChecked={isChecked} backgroundColor={backgroundColor}>
				<HiddenOnDesktop>
					<BrandLogo src={logo} alt={`${label} Logo`} isChecked={isChecked} />
				</HiddenOnDesktop>
				<BrandGlassImage
					isChecked={isChecked}
					rotation={rotation}
					src={image}
					alt={`${label} Glass`}
					height={imageHeight}
					width={imageWidth}
				/>
			</TopContainer>

			{/** Radio Content */}
			<BottomContainer>
				<HiddenOnMobile>
					<BrandLogo src={logo} alt={`${label} Logo`} isChecked={isChecked} />
				</HiddenOnMobile>
				<TitleContainer>
					<BrandSubTitle>With a pint of</BrandSubTitle>
					<BrandTitle isChecked={isChecked}>{label}</BrandTitle>
				</TitleContainer>
				<Radio
					sx={{
						transition: 'all 1s',
						color: '#757575',
						'&.Mui-checked': {
							color: 'black',
						},
					}}
					onClick={clicked}
					checked={isChecked}
				/>
			</BottomContainer>
		</StyledContainer>
	);
};

export default BrandOption;
