import { Button as MuiButton } from '@mui/material';
import styled from 'styled-components';

export interface ButtonProps {
	children?: React.ReactNode;
	onClick?: (event: any) => void;
	icon?: any;
	isDisabled?: boolean;
	isSecondary?: boolean;
	isInvert?: boolean;
	fullWidth?: boolean;
	className?: string;
}

const StyledButton = styled(MuiButton)<{
	isSecondary?: boolean;
	isInvert?: boolean;
}>`
	&& {
		color: ${({ isSecondary, isInvert }) =>
			isSecondary || isInvert ? '#C46710' : '#fff'};
		font-family: 'Gotham-Bold';
		border-radius: 16px;
		padding: ${({ isSecondary }) => (isSecondary ? '12px 32px' : '16px')};
		background: ${({ isSecondary, isInvert }) =>
			isSecondary
				? '#c467103d'
				: isInvert
				? 'white'
				: 'radial-gradient(50% 50% at 50% 50%, #c46710, #ba5d0a)'};
		gap: 0;
		font-size: 16px;

		&.Mui-disabled {
			color: #6c6c6c;
			background: #0000001f;
		}
	}
`;

const Button = ({
	children,
	onClick,
	icon,
	isDisabled = false,
	isSecondary = false,
	isInvert,
	fullWidth,
	className,
}: ButtonProps) => {
	const handleOnClick = () => {
		return;
	};

	return (
		<StyledButton
			className={className}
			startIcon={icon ?? undefined}
			onClick={(event) => (onClick ? onClick(event) : handleOnClick())}
			disabled={isDisabled}
			isSecondary={isSecondary}
			fullWidth={fullWidth}
			isInvert={isInvert}
		>
			{children}
		</StyledButton>
	);
};

export default Button;
