export enum VIEWPORT {
	MOBILE_EXTRA_SMALL = '400px',
	MOBILE = '450px',
	DESKTOP = '960px',
	EXTRA_LARGE_DESKTOP = '1800px',
}

export enum VIEWPORT_HEIGHT {
	MOBILE_EXTRA_SMALL = '850px',
}

export enum BREAKPOINT {
	MOBILE_EXTRA_SMALL = 400,
	MOBILE = 450,
	DESKTOP = 960,
	EXTRA_LARGE_DESKTOP = 1800,
}
