import { useState, useEffect, useRef } from 'react';

const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const prevWindowSize = useRef(windowSize);

	useEffect(() => {
		const handleResize = () => {
			prevWindowSize.current = windowSize;
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, [windowSize]);

	return { windowSize, prevWindowSize: prevWindowSize.current };
};

export default useWindowSize;
