import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import BrandOption from './BrandOption';

// Glass Images
import HeinekenGlass from '../assets/heineken_glass.png';
import TigerGlass from '../assets/tiger_glass.png';
import ExportGlass from '../assets/export_glass.png';

// Brand Logos
import HeinekenLogo from '../assets/brands/heineken.svg';
import TigerLogo from '../assets/brands/tiger.svg';
import ExportLogo from '../assets/brands/export.svg';

interface BrandSelectorProps {
	onClick?: (selectedBrands: { [key: string]: boolean }) => void;
	userSelectedBrands?: { [key: string]: boolean };
}

export const options = {
	heineken: {
		id: 'heineken',
		label: 'Heineken',
		image: HeinekenGlass,
		logo: HeinekenLogo,
		rotation: -13.66,
		backgroundColor: '#0171253D',
		imageHeight: 134,
		imageWidth: 70,
	},
	tiger: {
		id: 'tiger',
		label: 'Tiger',
		image: TigerGlass,
		logo: TigerLogo,
		rotation: 13.66,
		backgroundColor: '#22356F3D',
		imageHeight: 122.14,
		imageWidth: 79.17,
	},
	export: {
		id: 'export',
		label: 'Export',
		image: ExportGlass,
		logo: ExportLogo,
		rotation: -13.66,
		backgroundColor: '#FED0003D',
		imageHeight: 122.14,
		imageWidth: 70.72,
	},
};

const BrandSelector = ({ onClick, userSelectedBrands }: BrandSelectorProps) => {
	const [selectedBrands, setSelectedBrands] = useState<
		{ [key: string]: boolean } | undefined
	>(undefined);

	const handleClick = (id: string) => {
		const currentBrands = userSelectedBrands || selectedBrands;
		const updatedSelectedBrands = {
			...currentBrands,
			[id]: !currentBrands?.[id],
		};

		if (userSelectedBrands) {
			onClick?.(updatedSelectedBrands);
		} else {
			setSelectedBrands(updatedSelectedBrands);
			onClick?.(updatedSelectedBrands);
		}
	};

	useEffect(() => {
		let brands: { [key: string]: boolean } = {};

		Object.values(options).forEach((brand) => {
			brands = {
				...brands,
				[brand.id]: false,
			};
		});

		setSelectedBrands(brands);
	}, []);

	return (
		<Box
			display="flex"
			flexDirection="row"
			gap={'16px'}
			width={'100%'}
			justifyContent={'center'}
		>
			{Object.values(options).map((brand) => {
				const brands = userSelectedBrands || selectedBrands;

				if (!brands) return <></>;

				return (
					<BrandOption
						{...brand}
						isChecked={brands[brand.id]}
						clicked={() => handleClick(brand.id)}
					/>
				);
			})}
		</Box>
	);
};

export default BrandSelector;
