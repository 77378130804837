import { Box, createTheme, ThemeProvider, Typography } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';
import Button from './Button';

import DBLogo from '../assets/db_logo.svg';
import PartOfHeinekenBranding from '../assets/part_of_heineken.svg';

const AgeGateContainer = styled.div<{ isDisplayed?: boolean }>`
	transition: opacity 1s ease-in-out, visibility 1s ease-out;
	position: absolute;
	height: 100vh;
	width: 100vw;
	background: #c46210;
	z-index: 100;
	opacity: ${(props) => (props.isDisplayed ? '100%' : '0%')};
	visibility: ${(props) => (props.isDisplayed ? 'inherit' : 'hidden')};
`;

const AgeGate = () => {
	const [age, setAge] = useState<Dayjs | null>(dayjs());
	const [showAgeGate, setShowAgeGate] = useState<boolean>(true);
	const [isBlockedEntry, setIsBlockedEntry] = useState<boolean>(false);

	const theme = (theme: any) =>
		createTheme({
			...theme,
			components: {
				MuiDateCalendar: {
					styleOverrides: {
						root: {
							color: 'grey',
							borderRadius: '10px',
							borderWidth: '1px',
							borderColor: 'white',
							border: '1px solid',
							backgroundColor: 'white',
						},
					},
				},
				MuiOutlinedInput: {
					styleOverrides: {
						root: {
							color: 'white',
							borderColor: 'white',
						},
						notchedOutline: {
							borderColor: 'white',
						},
					},
				},
				MuiPickersDay: {
					styleOverrides: {
						root: {
							color: 'white',
							border: 'none',
							backgroundColor: '#C46210',
							'&:focus': {
								background: 'none',
								'&.Mui-selected': {
									background: 'none',
									border: '1px solid #C46210',
									color: '#C46210',
								},
							},
							'&.Mui-selected': {
								background: 'none',
								border: '1px solid #C46210',
								color: '#C46210',
							},
							'.MuiButtonBase-root-MuiButton-root': {
								color: 'red',
							},
						},
					},
				},
			},
		});

	const handleClick = () => {
		if (!age) {
			return;
		}

		if (isAdult()) {
			setShowAgeGate(false);
			return;
		}

		setIsBlockedEntry(true);
	};

	const isAdult = (): boolean => {
		const currentDate = dayjs();
		const actualAge = currentDate.diff(age, 'year');
		return actualAge >= 18;
	};

	return (
		<AgeGateContainer isDisplayed={showAgeGate}>
			<Box
				display="flex"
				flexDirection={'column'}
				textAlign="center"
				justifyContent="center"
				alignItems="center"
				width={'100vw'}
				height="100%"
				gap="32px"
			>
				<Box
					display="flex"
					flexDirection={'column'}
					flexWrap="wrap"
					gap="32px"
					justifyContent="center"
					padding={'30px'}
				>
					<img src={DBLogo} />
					<img src={PartOfHeinekenBranding} />
				</Box>
				{!isBlockedEntry ? (
					<>
						<Box>
							<Typography
								fontFamily={'Tungsten'}
								fontSize={'3rem'}
								color="white"
								lineHeight={'86%'}
							>
								WHEN WERE YOU BORN?
							</Typography>
							<Typography
								fontFamily={'Gotham'}
								fontSize={'1rem'}
								color="white"
								lineHeight={'86%'}
							>
								You must be over 18 to enter
							</Typography>
						</Box>
						<Box
							display="flex"
							flexWrap="wrap"
							gap="32px"
							justifyContent="center"
						>
							<ThemeProvider theme={theme}>
								<MobileDatePicker
									value={age}
									format="DD/MM/YYYY"
									onChange={(newValue) => setAge(newValue)}
									defaultValue={dayjs()}
									disableFuture={true}
								/>
							</ThemeProvider>
							<Button onClick={() => handleClick()} fullWidth isInvert>
								Enter
							</Button>
						</Box>
					</>
				) : (
					<Box
						display="flex"
						flexWrap="wrap"
						gap="32px"
						justifyContent="center"
					>
						<Typography
							fontFamily={'Tungsten'}
							fontSize={'3rem'}
							color="white"
							lineHeight={'86%'}
						>
							You must be over the legal drinking age in New Zealand to view
							this site
						</Typography>
						<Typography
							fontFamily={'Gotham'}
							fontSize={'1rem'}
							color="white"
							lineHeight={'86%'}
						>
							Always drink responsibly. Always surf the web responsibly too. You
							have to be 18 or older to enter this site.
						</Typography>
					</Box>
				)}
			</Box>
		</AgeGateContainer>
	);
};

export default AgeGate;
