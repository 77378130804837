import React from 'react';
import styled from 'styled-components';
import { VIEWPORT } from '../styles/viewport';

interface SlideProps {
	id: string;
	color: string;
	children: React.ReactNode;
	className?: string;
}

const SlideContainer = styled.div<{ color: string }>`
	position: relative;
	height: 100vh;
	width: 100vw;
	flex-shrink: 0;
	display: flex;
	justify-content: center;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		width: ${VIEWPORT.DESKTOP};
	}
`;

const Slide = ({ ...props }: SlideProps) => (
	<SlideContainer id={props.id} color={props.color} className={props.className}>
		{props.children}
	</SlideContainer>
);

export default Slide;
