import { styled } from 'styled-components';
import Button from './Button';
import { ButtonProps } from './Button';

const StyledButtonOutlined = styled(Button)`
	&& {
		color: #c46710;
		font-family: 'Gotham-Bold';
		border-radius: 16px;
		background: #ebebeb;
		padding: 15px 32px;
		gap: 0;
		font-size: 16px;
		border: 1px solid #c46710;

		&.Mui-disabled {
			color: #6c6c6c;
			background: #0000001f;
		}
	}
`;

const ButtonOutlined = ({ ...props }: ButtonProps) => {
	return (
		<StyledButtonOutlined {...props} className={props.className}>
			{props.children}
		</StyledButtonOutlined>
	);
};

export default ButtonOutlined;
