import beerTaps from '../data/beer-taps.json';
import { Brand } from '../type/brand';

export interface Outlet {
	outlet: string;
	address: string;
	region: string;
	link: string;
	heineken: boolean;
	tiger: boolean;
	export: boolean;
}

export const getUniqueLocations = (): string[] => {
	const uniqueLocations = new Set<string>();

	beerTaps.forEach((outlet: Outlet) => {
		uniqueLocations.add(outlet.region);
	});

	return Array.from(uniqueLocations).sort();
};

export const filterOutlets = (
	location: string,
	brands: Array<'heineken' | 'tiger' | 'export'>
): Outlet[] => {
	return beerTaps
		.filter((outlet: Outlet) => {
			return (
				outlet.region === location &&
				brands.some((brand) => outlet[brand] === true)
			);
		})
		.sort((a, b) => a.outlet.localeCompare(b.outlet));
};

export const getBrands = (outlet: Outlet): Brand[] => {
	const outlets = [];

	if (outlet.export) {
		outlets.push(Brand.EXPORT);
	}

	if (outlet.heineken) {
		outlets.push(Brand.HEINEKEN);
	}

	if (outlet.tiger) {
		outlets.push(Brand.TIGER);
	}

	return outlets;
};
