import { Box, Card as MuiCard, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Button from './Button';
import BrandChip from './BrandChip';
import { Brand } from '../type/brand';
import styled from 'styled-components';

interface CardProps {
	label?: string;
	address?: string;
	brands?: Brand[];
	solidBackground?: boolean;
	link?: string;
}

const BrandSubTitle = styled.div`
	font-family: 'Gotham';
	font-weight: 325;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: #757575;
`;

const Card = ({ label, address, brands, link }: CardProps) => {
	const handleClick = () => {
		window.open(link, '_blank');
	};

	return (
		<MuiCard style={{ borderRadius: '24px', width: '368px' }}>
			<Box padding={'16px'}>
				<Box display={'flex'} gap={'16px'} flexDirection={'column'}>
					<Box display="flex" gap="8px" flexDirection="column">
						<Typography
							variant="h3"
							fontFamily={'Gotham-Bold'}
							fontSize={'32px'}
							color="#000000DE"
						>
							{label}
						</Typography>
						<Box display="flex" flexDirection={'row'} gap="8px">
							<LocationOnOutlinedIcon
								sx={{ color: '#757575', width: '16px' }}
							/>
							<BrandSubTitle>{address}</BrandSubTitle>
						</Box>
					</Box>
					<Box display="flex" flexDirection="column" gap="32px">
						<Box display="flex" flexDirection="row" gap={'8px'}>
							{brands?.map((brand) => {
								return <BrandChip type={brand} />;
							})}
						</Box>
						<Box>
							<Button isSecondary={true} onClick={handleClick}>
								Go to Bar
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</MuiCard>
	);
};

export default Card;
