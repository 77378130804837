import { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Slide from './components/Slide';
import styled from 'styled-components';
import Button from './components/Button';
import { SportsBarOutlined } from '@mui/icons-material';
import BrandSelector, { options } from './components/BrandSelector';
import {
	Box,
	createTheme,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	ThemeProvider,
	Typography,
} from '@mui/material';
import DBBreweriesLogo from './assets/brands/dbbreweries.svg';
import BeerWavesFill from './assets/beer-waves.svg';

// Bottle Images
import HeinekenBottle from './assets/heineken_glass.png';
import HeinekenBottleTwo from './/assets/heineken_glass_2.png';
import ExportBottle from './assets/export_glass.png';
import TigerBottle from './assets/tiger_glass.png';

import Card from './components/Card';
import { Brand } from './type/brand';
import BrandChip from './components/BrandChip';
import ButtonOutlined from './components/ButtonOutlined';
import {
	filterOutlets,
	getBrands,
	getUniqueLocations,
	Outlet,
} from './util/outlets';
import { BREAKPOINT, VIEWPORT, VIEWPORT_HEIGHT } from './styles/viewport';
import { LocalizationProvider } from '@mui/x-date-pickers';
import useWindowSize from './hooks/useWindowSize';
import AgeGate from './components/AgeGate';

const Container = styled.div`
	position: relative;
	overflow: hidden;
	height: 100vh;
	background: radial-gradient(
			240.17% 165.67% at 82.44% 19.9%,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.36) 100%
		),
		#f7f7f7;
`;

interface SlideContainerProps {
	activeSlide: number;
}

const SlideContainer = styled.div<SlideContainerProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: transform 1s ease-in-out;
	transform: ${({ activeSlide }) =>
		`translateY(${(activeSlide - 1) * -100}vh)`};
	height: 300vh;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		height: 200vh;
		transform: ${({ activeSlide }) =>
			`translateY(${(activeSlide - 1) * -100}vh)`};
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 30px;
	height: 100%;
	width: 100%;
	margin: 16px;

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		gap: 0;
	}
`;

const ScrollableContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	padding: 20px;
	padding-top: 100px;
	gap: 32px;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		width: 100vw;
		overflow: auto;
	}
`;

const HeaderContainer = styled.div`
	position: absolute;
	top: 0;
	left: 5%;
`;

const HeaderLogo = styled.img`
	position: sticky;
	z-index: 20;
	top: 0;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		width: 159px;
		height: 162px;
	}
`;

const HeinekenBottleImage = styled.img<{ isDisplayed?: boolean }>`
	transition: left 1.8s ease-in-out, transform 2s;
	position: absolute;
	width: 208.062px;
	height: 507.75px;
	transform: ${(props) =>
		props.isDisplayed ? 'rotate(11.482deg)' : 'rotate(-11deg)'};
	left: ${(props) => (props.isDisplayed ? '-25%' : '-100%')};
	top: 3%;
	z-index: -1;
	animation: ${(props) =>
		props.isDisplayed ? 'heinekenBottle 3s ease-in-out infinite' : 'none'};

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		width: 166px;
		height: 405px;
	}

	@keyframes heinekenBottle {
		0% {
			transform: rotate(11deg);
			left: -25%;
		}
		50% {
			transform: rotate(8deg);
			left: -23%;
		}
		100% {
			transform: rotate(11deg);
			left: -25%;
		}
	}

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		top: -10%;
		left: ${(props) => (props.isDisplayed ? '-10%' : '-100%')};
		width: 279px;
		height: 682px;
	}

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) and (min-width: ${VIEWPORT.DESKTOP}) {
		left: ${(props) => (props.isDisplayed ? '-25%' : '-100%')};
		top: 3%;
		width: 166px;
		height: 405px;
	}
`;

const HeinekenBottleImageTwo = styled.img<{ isDisplayed?: boolean }>`
	transition: right 1.5s ease-in-out, transform 2s;
	position: absolute;
	width: 198.561px;
	height: 437.15px;
	transform: ${(props) =>
		props.isDisplayed ? 'rotate(-15deg)' : 'rotate(15deg)'};
	right: ${(props) => (props.isDisplayed ? '-10%' : '-100%')};
	top: -5%;
	z-index: -1;
	animation: ${(props) =>
		props.isDisplayed ? 'bottle 5s ease-in-out infinite' : 'none'};

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		width: 166px;
		height: 367px;
	}

	@keyframes bottle {
		0% {
			transform: rotate(-15deg);
			top: -5%;
		}
		50% {
			transform: rotate(-10deg);
			top: -4%;
		}
		100% {
			transform: rotate(-15deg);
			top: -5%;
		}
	}

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		top: -10%;
		right: ${(props) => (props.isDisplayed ? '-25%' : '-100%')};
		width: 313.255px;
		height: 689.66px;
	}

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) and (min-width: ${VIEWPORT.DESKTOP}) {
		top: -10%;
		right: ${(props) => (props.isDisplayed ? '-25%' : '-100%')};
		width: 166px;
		height: 367px;
	}
`;

const ExportBottleImage = styled.img<{ isDisplayed?: boolean }>`
	transition: left 1s ease-in-out, transform 2s, opacity 1s ease-in-out;
	position: absolute;
	width: 247.642px;
	height: 394.07px;
	transform: ${(props) =>
		props.isDisplayed ? 'rotate(-15deg)' : 'rotate(15deg)'};
	left: ${(props) => (props.isDisplayed ? '-15%' : '-100%')};
	bottom: -5%;
	z-index: -1;
	opacity: ${(props) => (props.isDisplayed ? '1' : '0')};

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		width: 166px;
		height: 265px;
	}

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		bottom: -35%;
		left: ${(props) => (props.isDisplayed ? '-30%' : '-100%')};
		width: 437.751px;
		height: 696.589px;
	}

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) and (min-width: ${VIEWPORT.DESKTOP}) {
		left: ${(props) => (props.isDisplayed ? '-15%' : '-100%')};
		bottom: -5%;
		width: 166px;
		height: 265px;
	}
`;

const TigerBottleImage = styled.img<{ isDisplayed?: boolean }>`
	transition: right 1.5s ease-in-out, transform 2s, bottom 1.5s ease-in-out,
		opacity 1s ease-in-out;
	position: absolute;
	width: 283.443px;
	height: 445.269px;
	bottom: ${(props) => (props.isDisplayed ? '-15%' : '-30%')};
	transform: ${(props) =>
		props.isDisplayed ? 'rotate(13.59deg)' : 'rotate(-13.59deg)'};
	right: ${(props) => (props.isDisplayed ? '-18%' : '-50%')};
	opacity: ${(props) => (props.isDisplayed ? '1' : '0')};
	z-index: -1;

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		width: 150px;
		height: 261px;
	}

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		width: 460.926px;
		height: 724.082px;
		bottom: -25%;
		right: ${(props) => (props.isDisplayed ? '-35%' : '-100%')};
	}

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) and (min-width: ${VIEWPORT.DESKTOP}) {
		right: ${(props) => (props.isDisplayed ? '-18%' : '-50%')};
		bottom: ${(props) => (props.isDisplayed ? '-15%' : '-30%')};
		width: 150px;
		height: 261px;
	}
`;

const LastSlide = styled(Slide)`
	position: relative;
	overflow-y: scroll;
	overflow-x: hidden;
	align-items: start;
	background: none;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		max-width: 100vw;
		width: 100%;
	}
`;

const SubText = styled.div`
	color: rgba(255, 255, 255, 0.72);
	text-align: center;
	font-family: 'Gotham-Bold';
	font-size: 16px;
	font-style: normal;
	text-transform: uppercase;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		font-size: 18px;
	}
`;

const InteractableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	text-align: center;
	gap: 72px;
	padding-top: 32px;
	padding-bottom: 32px;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		gap: 40px;
	}

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		gap: 10px;
	}
`;

const LocationText = styled.div`
	color: #fff;
	text-align: center;
	font-family: 'Tungsten';
	text-transform: uppercase;
	line-height: 100%;
	width: 100%;
	font-size: clamp(80px, 112px, 112px);

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		font-size: 80px;
	}

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		font-size: 220px;
	}
`;

const BeerWaves = styled.div<{ isDisplayed?: boolean }>`
	transition: top 1s ease-in-out;
	position: absolute;
	left: 0;
	top: ${(props) => (props.isDisplayed ? '20%' : '150%')};
	background: url(${BeerWavesFill});
	background-repeat: no-repeat;
	background-size: cover;
	width: 2889px;
	height: auto;
	min-height: 100vh;
	overflow: hidden;
	animation: wave 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
	transform: translate3d(0, 0, 0);

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		top: ${(props) => (props.isDisplayed ? '25%' : '150%')};
	}

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		width: 2889px;
		top: ${(props) => (props.isDisplayed ? '20%' : '150%')};
		z-index: auto;
	}

	@media (min-width: ${VIEWPORT.EXTRA_LARGE_DESKTOP}) {
		width: 5000px;
	}

	&:nth-of-type(2) {
		width: 4000px;
		top: ${(props) => (props.isDisplayed ? '18%' : '150%')};
		transform: scaleX(-1);
		opacity: 0.5;
		animation: wave 10s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
			swell 20s ease-in-out -1.25s infinite;

		@media (min-width: ${VIEWPORT.DESKTOP}) {
			top: ${(props) => (props.isDisplayed ? '17%' : '150%')};
			z-index: auto;
		}
	}

	@keyframes wave {
		0% {
			left: 0%;
		}
		50% {
			left: -20%;
		}
		100% {
			left: 0%;
		}
	}

	@keyframes swell {
		0% {
			transform: translate3d(0, 0, 0);
		}
		50% {
			transform: translate3d(0, 5px, 0);
		}
		100% {
			transform: translate3d(0, 0, 0);
		}
	}
`;

const Slide1Header = styled.h1`
	font-family: 'Tungsten';
	font-size: 120px;
	color: #c46210;
	line-height: 86%;
	margin: 0;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		font-size: 200px;
	}

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		font-size: 100px;
	}
`;

const Slide1SubHeader = styled.h1`
	font-family: 'Tungsten';
	font-size: 48px;
	color: #616161;
	line-height: 86%;
	margin: 0;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		font-size: 64px;
	}

	@media screen and (max-height: ${VIEWPORT_HEIGHT.MOBILE_EXTRA_SMALL}) {
		font-size: 48px;
	}
`;

const BrandSelectorContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		gap: 40px;
	}
`;

const TitleContainer = styled.div`
	text-align: center;
	padding: 56px;
	max-width: 410px;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		padding: 0;
	}
`;

const CardContainer = styled.div`
	display: flex;
	position: relative;
	width: 100vw;
	align-items: center;
	flex-direction: column;
	gap: 16px;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		width: 100%;
	}
`;

const HiddenOnMobile = styled.div`
	display: none;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		display: inherit;
	}
`;

const HiddenOnDesktop = styled.div`
	display: inherit;

	@media (min-width: ${VIEWPORT.DESKTOP}) {
		display: none;
	}
`;

const App = () => {
	const [locations, setLocations] = useState<string[]>([]);
	const [selectedLocation, setSelectedLocation] = useState<string>('');
	const [outlets, setOutlets] = useState<Outlet[]>();
	const [selectedBrands, setSelectedBrands] = useState<
		{ [key: string]: boolean } | undefined
	>(undefined);
	const [isDisabled, setIsDisabled] = useState<boolean>(true);
	const [displayBeerWave, setDisplayBeerWave] = useState<boolean>(false);
	const [isBeerDisplayed, setIsBeerDisplayed] = useState<boolean>(true);
	const { windowSize, prevWindowSize } = useWindowSize();

	const theme = (theme: any) =>
		createTheme({
			...theme,
			typography: {
				h3: {
					fontFamily: 'Gotham-Bold',
					fontSize: '16px',
					color: '#757575',
				},
			},
		});

	const [activeSlide, setActiveSlide] = useState(1);

	const handleSelectedBrands = (brands: { [key: string]: boolean }) => {
		setSelectedBrands(brands);
	};

	const mapBrand = () => {
		const displayedBrands: Brand[] = [];
		const selectedBrands = getSelectedBrands();

		selectedBrands?.map((brand) => {
			switch (brand) {
				case 'heineken':
					displayedBrands.push(Brand.HEINEKEN);
					break;
				case 'tiger':
					displayedBrands.push(Brand.TIGER);
					break;
				case 'export':
					displayedBrands.push(Brand.EXPORT);
					break;
			}
		});

		return displayedBrands;
	};

	const isDesktopViewport = (): boolean => {
		return window.innerWidth >= 960;
	};

	const handleSlideChange = (slideIndex: number): void => {
		if (slideIndex === 3 && !isDesktopViewport()) {
			getOutlets();
		}

		if (slideIndex === 2 && isDesktopViewport()) {
			getOutlets();
		}

		setActiveSlide(slideIndex);
	};

	const getSelectedBrands = () => {
		const brands: Array<'heineken' | 'tiger' | 'export'> = [];

		if (!selectedBrands) return;
		Object.entries(selectedBrands).forEach(([brand, isSelected]) => {
			if (!isSelected) {
				return;
			}

			switch (brand) {
				case 'heineken':
					brands.push('heineken');
					break;
				case 'tiger':
					brands.push('tiger');
					break;
				case 'export':
					brands.push('export');
					break;
			}
		});

		return brands;
	};

	const getOutlets = () => {
		const brands = getSelectedBrands();
		if (!brands) return;

		const filteredOutlets = filterOutlets(selectedLocation, brands);
		setOutlets(filteredOutlets);
	};

	const isAtLeastOneOptionSelected = (): boolean => {
		let selected = false;

		if (!selectedBrands) return false;

		Object.entries(selectedBrands).forEach(([brand, isSelected]) => {
			console.log({ [brand]: isSelected });
			if (isSelected) {
				selected = true;
			}
		});

		return selected;
	};

	const calculateBeerWave = () => {
		if (activeSlide === 2 && isDesktopViewport()) {
			setDisplayBeerWave(true);
			return;
		}

		if (activeSlide === 3 && !isDesktopViewport()) {
			setDisplayBeerWave(true);
			return;
		}

		setDisplayBeerWave(false);
	};

	const calculateBeerDisplay = () => {
		if (activeSlide !== 1) {
			setIsBeerDisplayed(false);
			return;
		}

		setIsBeerDisplayed(true);
	};

	useEffect(() => {
		const uniqueLocations = getUniqueLocations();
		setLocations(uniqueLocations);
	}, []);

	useEffect(() => {
		if (!selectedLocation) {
			setIsDisabled(true);
			return;
		}

		if (!isAtLeastOneOptionSelected()) {
			setIsDisabled(true);
			return;
		}

		setIsDisabled(false);
	}, [selectedLocation, selectedBrands]);

	useEffect(() => {
		calculateBeerWave();
		calculateBeerDisplay();
	}, [activeSlide]);

	useEffect(() => {
		// User switches from mobile to desktop
		if (prevWindowSize.width < BREAKPOINT.DESKTOP) {
			if (!(windowSize.width >= BREAKPOINT.DESKTOP)) {
				return;
			}

			switch (activeSlide) {
				case 2:
					setActiveSlide(1);
					return;
				case 3:
					setActiveSlide(2);
				default:
					return;
			}
		}

		// User switches from desktop to mobile
		if (prevWindowSize.width >= BREAKPOINT.DESKTOP) {
			if (!(windowSize.width < BREAKPOINT.DESKTOP)) {
				return;
			}

			switch (activeSlide) {
				case 1:
					setActiveSlide(2);
					return;
				case 2:
					setActiveSlide(3);
					return;
				default:
					return;
			}
		}
	}, [windowSize.width]);

	useEffect(() => {
		let brands: { [key: string]: boolean } = {};

		Object.values(options).forEach((brand) => {
			brands = {
				...brands,
				[brand.id]: false,
			};
		});

		setSelectedBrands(brands);
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Container>
					<AgeGate />
					<BeerWaves isDisplayed={displayBeerWave} />
					<BeerWaves isDisplayed={displayBeerWave} />
					<HeaderContainer>
						<HeaderLogo src={DBBreweriesLogo} />
					</HeaderContainer>
					<SlideContainer activeSlide={activeSlide}>
						<Slide
							id="slide1"
							color="radial-gradient(233.02% 107.51% at 82.44% 19.9%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.36) 100%)"
						>
							<HeinekenBottleImage
								src={HeinekenBottle}
								isDisplayed={isBeerDisplayed}
							/>
							<HeinekenBottleImageTwo
								src={HeinekenBottleTwo}
								isDisplayed={isBeerDisplayed}
							/>
							<ExportBottleImage
								src={ExportBottle}
								isDisplayed={isBeerDisplayed}
							/>
							<TigerBottleImage
								src={TigerBottle}
								isDisplayed={isBeerDisplayed}
							/>
							<Content>
								<TitleContainer>
									<Slide1SubHeader>FRESH, COLD</Slide1SubHeader>
									<Slide1Header>BEER</Slide1Header>
									<Typography
										fontFamily={'Gotham'}
										font-size="16px"
										color="#434343"
									>
										These bars, restaurants and venues are pouring fresh, cold
										pints of your favourite beer. Find your local and pay a
										visit to check out deals on offer.
									</Typography>
								</TitleContainer>
								<HiddenOnMobile>
									<InteractableContainer>
										{/* Location Selector */}
										<Box
											display="flex"
											flexDirection="column"
											gap="8px"
											justifyContent="center"
											alignContent="center"
											alignItems="center"
										>
											<Typography variant="h3">Select Your Location</Typography>
											<FormControl sx={{ width: '410px' }}>
												<InputLabel id="demo-simple-select-label">
													Choose your region
												</InputLabel>

												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={selectedLocation}
													onChange={(event) =>
														setSelectedLocation(event.target.value)
													}
													label="Choose your region"
													sx={{
														maxWidth: '480px',
													}}
												>
													{locations.map((location) => {
														return (
															<MenuItem value={location}>{location}</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										</Box>

										{/* Brand Selector */}
										<BrandSelectorContainer>
											<Typography variant="h3">Select your beer</Typography>
											<BrandSelector
												onClick={handleSelectedBrands}
												userSelectedBrands={selectedBrands}
											/>
										</BrandSelectorContainer>

										{/* Go to slide 3 */}
										<Box display="flex" justifyContent={'center'}>
											<Box width={'100%'} maxWidth="410px">
												<Button
													onClick={() => handleSlideChange(2)}
													icon={<SportsBarOutlined />}
													fullWidth
													isDisabled={isDisabled}
												>
													Find your local
												</Button>
											</Box>
										</Box>
									</InteractableContainer>
								</HiddenOnMobile>

								<Box width={'80%'}>
									<HiddenOnDesktop>
										<Button
											onClick={() => handleSlideChange(2)}
											icon={<SportsBarOutlined />}
											isInvert
											fullWidth
										>
											Find your local
										</Button>
									</HiddenOnDesktop>
								</Box>
							</Content>
						</Slide>

						<HiddenOnDesktop>
							<Slide
								id="slide2"
								color="radial-gradient(233.02% 107.51% at 82.44% 19.9%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.36) 100%)"
							>
								<Content>
									<Box textAlign="center">
										<Typography
											fontFamily={'Tungsten'}
											fontSize={'24px'}
											color="#616161"
											lineHeight={'86%'}
										>
											FRESH, COLD
										</Typography>
										<Typography
											fontFamily={'Tungsten'}
											fontSize={'56px'}
											color="#C46210"
											lineHeight={'86%'}
										>
											BEER
										</Typography>
									</Box>
									<InteractableContainer>
										{/* Location Selector */}
										<Box display="flex" flexDirection="column" gap="8px">
											<Typography variant="h3">Select Your Location</Typography>
											<FormControl fullWidth>
												<InputLabel id="demo-simple-select-label">
													Choose your region
												</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={selectedLocation}
													onChange={(event) =>
														setSelectedLocation(event.target.value)
													}
													label="Choose your region"
												>
													{locations.map((location) => {
														return (
															<MenuItem value={location}>{location}</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										</Box>

										{/* Brand Selector */}
										<Box display="flex" flexDirection="column" gap="24px">
											<Typography variant="h3">Select your beer</Typography>
											<BrandSelector
												onClick={handleSelectedBrands}
												userSelectedBrands={selectedBrands}
											/>
										</Box>

										{/* Go to slide 3 */}
										<Button
											onClick={() => handleSlideChange(3)}
											icon={<SportsBarOutlined />}
											fullWidth
											isDisabled={isDisabled}
										>
											Find Offers
										</Button>
									</InteractableContainer>
								</Content>
							</Slide>
						</HiddenOnDesktop>

						<LastSlide
							id="slide3"
							color="radial-gradient(233.02% 107.51% at 82.44% 19.9%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.36) 100%)"
						>
							<ScrollableContent>
								<Box display={'flex'} flexDirection={'column'} gap="16px">
									<Box
										display={'flex'}
										justifyContent={'center'}
										paddingBottom={'32px'}
										width={'100%'}
									>
										{window.innerWidth >= 960 ? (
											<ButtonOutlined onClick={() => handleSlideChange(1)}>
												Change my choices
											</ButtonOutlined>
										) : (
											<ButtonOutlined onClick={() => handleSlideChange(2)}>
												Change my choices
											</ButtonOutlined>
										)}
									</Box>
									<Box>
										<SubText>
											Bars, restaurants and venues with offers in
										</SubText>
										<LocationText>{selectedLocation}</LocationText>
									</Box>
									<SubText>With Offers On</SubText>
									<Box display={'flex'} gap="4px" justifyContent={'center'}>
										{mapBrand().map((brand) => {
											return <BrandChip type={brand} backgroundColor="#fff" />;
										})}
									</Box>
								</Box>
								<CardContainer>
									{outlets?.map((outlet) => {
										return (
											<Card
												label={outlet.outlet}
												address={outlet.address}
												brands={getBrands(outlet)}
												link={outlet.link}
												key={outlet.outlet}
											/>
										);
									})}
								</CardContainer>
							</ScrollableContent>
						</LastSlide>
					</SlideContainer>
				</Container>
			</LocalizationProvider>
		</ThemeProvider>
	);
};

export default App;
